@import '../../styles/typography.scss';

.container {
	display: flex;
	height: 100vh;

	@media screen and (max-width: 991px) {
		flex-direction: column;
		height: 100%;


	}

	&-col {
		width: 50%;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		height: 100%;
		padding: 3rem 5%;

		@media screen and (max-width: 991px) {
			width: 100%;

		}

		.subheader {

			@include font-xxl(500, normal, rgba(237, 103, 44, 1), 1.18rem, uppercase);
			font-family: Montserrat;
			line-height: 47px;
			letter-spacing: 0.46em;

			@media screen and (max-width: 991px) {
				padding-top: 53px;
				font-size: 0.9rem;
				line-height: 38.58px;
			}
		}

		.header {

			@include font-xxl(700, normal, rgba(237, 103, 44, 1), 1.75rem, uppercase);
			font-family: Montserrat;
			width: 100%;

			@media screen and (max-width: 1200px) {
				font-size: 1.8rem;
				width: 70%;
			}

			@media screen and (max-width: 991px) {
				font-size: 1.6rem;
			}
		}

		&:first-child {
			background-color: var(--color-yellow);
		}

		img {
			width: 100%;
		}

		.register-form {
			display: flex;
			flex-direction: column;
			gap: 2rem;
			width: 80%;
			margin: auto;

			@media screen and (max-width: 1200px) {
				width: 100%;
			}

			button {
				color: #FFF;

				text-align: center;
				font-size: 1.125rem;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				letter-spacing: 0.09rem;
				text-transform: uppercase;
				height: 3.625rem;
				border-radius: 0rem 1.8125rem 1.8125rem 1.8125rem;
				background: #1C5EF6;
				outline: none;
				border: none;
				display: flex;
				align-items: center;
				justify-content: center;

			}

			&-input-container {
				display: flex;
				align-items: center;
				justify-content: space-between;
				gap: 1rem;
			}

			.form-error {
				border-bottom: 4px solid #fb5b5b;
				color: #fb5b5b;
				padding-inline-start: 1rem;
			}
		}
	}

	&-contact {
		display: flex;
		padding-top: 12%;
		gap: 20%;

		@media screen and (max-width: 991px) {
			flex-direction: column;
			justify-content: space-between;
			gap: 2rem;
		}

		ul {
			list-style: none;

			li {
				h5 {
					text-transform: uppercase;
				}

				margin-bottom: 1rem;
				color: #1C5EF6;
				font-size: 1.3rem;
				font-style: normal;
				font-weight: 400;
			}
		}
	}

}
.footer-passion {
	background-color: #ED672C;
	display: flex;
	justify-content: space-between;
	padding-inline: 4%;
	padding-top: 1rem;
	padding-bottom: 1rem;

	@media screen and (max-width: 991px) {
		flex-direction: column;
		gap: 1rem;
		align-items: center;
	}

	span {
		opacity: 0.8;
		color: #FFF;
		font-size: 0.875rem;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		letter-spacing: 0.175rem;
		text-transform: uppercase;

		a {
			color: #C4FB71;
			text-decoration: none;

		}
	}
}