@import '../../styles/typography.scss';

/* .container{
	padding-inline: 10%;
} */
* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

.home {
	// position: relative;

	&-upper {
		background-color: var(--color-yellow);
		height: 100vh;
		min-height: 50rem;
		padding-inline: 10%;
		position: relative;

		@media screen and (min-width: 786px) and (max-width: 991px) {
			height: 110vh;
		}

		.football-bg {
			position: absolute;
			top: 10%;
			width: 70%;
			height: 120%;
			object-fit: contain;
			z-index: 5;
			display: block;

			@media screen and (max-width: 1660px) {
				height: 120%;
			}

			@media screen and (max-width: 991px) {
				display: none;
			}
		}

		.football-neo-well-resp {
			display: none;

			@media screen and (max-width: 991px) {
				display: block;
				position: absolute;
				bottom: 24%;
				z-index: 7;
				left: 50%;
				transform: translate(-50%);
				width: 57%;
			}

			@media screen and (max-width: 786px) {
				width: 85%;
			}

			@media screen and (max-width: 680px) {
				bottom: 33%;
			}

			@media screen and (max-width: 387px) {
				bottom: 40%;
			}
		}

		.triangles-bg {
			position: absolute;
			right: 1%;
			width: 49%;
			bottom: 0;
			z-index: 0;

			@media screen and (max-width: 991px) {
				right: -38%;
				width: 148%;
			}
		}

		.text-hero {
			/* max-width: 490px;
			width: 100%; */
			position: absolute;
			right: 19%;
			width: 49%;
			bottom: 125px;
			z-index: 0;

			@include font-xxl(500, normal, #1c5ef6, 1.3125rem, none);

			font-family: Montserrat;
			line-height: 25.6px;

			@media screen and (min-width: 1200px) {
				width: 28%;
				bottom: 85px;
			}

			@media screen and (min-width: 992px) and (max-width: 1200px) {
				bottom: 65px;
			}

			@media screen and (min-width: 991px) {
				width: 28%;
			}

			@media screen and (max-width: 991px) {
				bottom: 5%;
				width: 90%;
				left: 50%;
				transform: translateX(-50%);
				@include font-xxl(500, normal, #1c5ef6, 1.2rem, none);
			}
		}

		.read-more-container {
			display: flex;
			gap: 40px;
			position: absolute;
			right: 5%;
			bottom: 20px;
			z-index: 0;
			cursor: pointer;
			text-decoration: none;
			@include font-xxl(500, normal, #ffffff, 1.125rem, none);
			line-height: 22px;
			text-transform: uppercase;

			@media screen and (max-width: 1700px) {
				gap: 10px;
			}

			@media screen and (max-width: 991px) {
				display: none;
				gap: 0px;
			}
		}

		.join-us-btn {
			width: 227px;
			padding: 15px 30px;

			border-radius: 0px 29px 29px 29px;
			background-color: #ed672c;
			border: none;

			font-family: Montserrat;
			line-height: 21.94px;
			letter-spacing: 0.08em;
			margin-top: 55px;

			display: none;
			text-decoration: none;

			@include font-xxl(500, normal, #fff, 1.1rem, uppercase);

			@media screen and (max-width: 991px) {
				display: block;
				margin-top: 1rem;
			}
		}
	}

	&-lower {
		display: flex;
		justify-content: space-between;
		background-color: rgba(237, 103, 44, 1);
		align-items: center;
		flex-direction: row;

		@media screen and (max-width: 991px) {
			flex-direction: column-reverse;
		}

		&-content {
			padding-inline: 10%;
			padding-top: 20%;
			padding-bottom: 10%;

			/* @media screen and (max-width: 1400px) {
				padding-bottom: 10%;
			} */

			@media screen and (max-width: 991px) {
				padding-block: 9rem 7rem;
				padding-inline: 10%;
				/* padding-bottom: 10%; */
			}

			.subheader {
				line-height: 30px;
				font-family: Montserrat;
				letter-spacing: 0.46em;
				@include font-xxl(500, normal, #fff, 1.2rem, uppercase);
				padding-bottom: 15px;

				@media screen and (max-width: 991px) {
					font-size: 0.875rem;
					padding-bottom: 13px;
				}
			}

			.header {
				background: linear-gradient(90deg, #c4fb71 0%, #fdf151 100%);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;

				@include font-xxl(700, normal, #fff, 2rem, uppercase);
				font-family: Montserrat;
				line-height: 47px;
				padding-bottom: 35px;

				@media screen and (max-width: 991px) {
					font-size: 1.5rem;
					line-height: 36px;
				}
			}

			.desc {
				@include font-xxl(500, normal, #fff, 1.3rem, none);
				font-family: Montserrat;
				line-height: 32px;
				padding-bottom: 37px;

				@media screen and (max-width: 991px) {
					font-size: 1rem;
					line-height: 26px;
				}
			}

			.read-more {
				width: 227px;
				height: 58px;

				border-radius: 0px 29px 29px 29px;
				background-color: rgba(28, 94, 246, 1);
				border: none;

				font-family: Montserrat;
				line-height: 21.94px;
				letter-spacing: 0.08em;

				@include font-xxl(500, normal, #fff, 1.1rem, uppercase);

				@media screen and (max-width: 991px) {
					max-width: 227px;
					width: 100%;

					font-size: 1rem;
					line-height: 19.5px;
					letter-spacing: 0.08em;
				}
			}
		}

		.pattern-img {
			height: 100%;
			width: 100%;
			display: flex;
			justify-content: end;

			@media screen and (max-width: 1400px) {
				height: 90vh;
			}

			@media screen and (max-width: 991px) {
				height: 100%;
			}

			img {
				height: 100%;
				width: 75%;
				object-fit: cover;

				@media screen and (max-width: 991px) {
					height: 300px;
					width: 100%;
				}

				@media screen and (max-width: 786px) {
					height: 402px;
					width: 100%;
				}
			}
		}
	}
}

.find-us {
	background-color: rgba(28, 94, 246, 1);
	padding-inline: 10%;
	height: 40vh;
	display: flex;
	justify-content: space-between;

	@media screen and (max-width: 991px) {
		flex-direction: column;
		height: auto;
		padding-inline: 0%;
	}

	&-content {
		display: flex;
		flex-direction: column;
		justify-content: center;

		@media screen and (max-width: 991px) {
			width: 100%;
			padding-inline: 12%;
		}

		.subheader {
			@include font-xxl(500, normal, rgba(237, 103, 44, 1), 1.18rem, uppercase);
			font-family: Montserrat;
			line-height: 47px;
			letter-spacing: 0.46em;

			@media screen and (max-width: 991px) {
				padding-top: 53px;
				font-size: 0.9rem;
				line-height: 38.58px;
			}
		}

		.header {
			@include font-xxl(700, normal, rgba(237, 103, 44, 1), 3.75rem, uppercase);
			font-family: Montserrat;
			max-width: 653px;
			width: 100%;

			@media screen and (max-width: 1200px) {
				font-size: 2.8rem;
				width: 70%;
			}

			@media screen and (max-width: 991px) {
				font-size: 2.6rem;
				line-height: 50.89px;
			}
		}

		.desc {
			display: none;
			@include font-xxl(500, normal, #fff, 1rem, none);
			line-height: 26px;
			font-family: Montserrat;
			margin-top: 45px;

			@media screen and (max-width: 991px) {
				display: block;
			}
		}
	}

	&-triangle {
		background-image: url('../../assets/media/img-triangle.webp');
		background-size: 200% 200%;
		cursor: pointer;
		text-decoration: none;
		max-width: 37%;
		display: flex;
		flex-direction: column;
		align-items: start;
		justify-content: end;

		@media screen and (max-width: 991px) {
			max-width: 100%;
			background-size: 370% 200%;
			height: 484px;
			justify-content: center;
			padding-top: 95px;
		}

		img {
			margin-bottom: 20px;
			padding-inline: 20px;

			@media screen and (max-width: 991px) {
				padding-inline: 10%;
			}
		}

		p {
			padding-inline: 20px;

			@include font-xxl(500, normal, #1c5ef6, 1.6rem, uppercase);
			font-family: Montserrat;
			line-height: 29px;
			max-width: 90%;

			@media screen and (max-width: 991px) {
				font-size: 1.56rem;
				max-width: 100%;
				padding-inline: 10%;
			}
		}

		.pin-address {
			margin-bottom: 20px;
			@include font-xxl(700, normal, #1c5ef6, 1.8rem, uppercase);

			@media screen and (max-width: 1200px) {
				margin-bottom: 35px;
			}
		}

		button {
			width: 227px;
			height: 58px;

			border-radius: 0px 29px 29px 29px;
			background-color: #ed672c;
			border: none;

			font-family: Montserrat;
			line-height: 21.94px;
			letter-spacing: 0.08em;
			margin-top: 75px;
			display: none;
			padding-inline: 10%;
			width: auto;

			@include font-xxl(500, normal, #fff, 1.1rem, uppercase);

			@media screen and (max-width: 991px) {
				display: block;
				margin-inline-start: 10%;
				font-size: 0.9rem;
			}
		}
	}
}

.program-activities {
	display: flex;
	position: relative;
	background-color: #fff;

	@media screen and (max-width: 991px) {
		flex-direction: column-reverse;
	}

	.content-container {
		padding-inline-start: 10%;
		padding-top: 70px;
		width: 39%;
	}

	&-content {
		@media screen and (max-width: 1400px) {
			width: 76%;
		}

		p {
			@include font-xxl(500, normal, #1c5ef6, 1.3rem, none);
			font-family: Montserrat;
			line-height: 32px;
			max-width: 548px;
			width: 100%;
			padding-bottom: 2rem;

			@media screen and (max-width: 991px) {
				display: none;
			}
		}

		a {
			@media screen and (max-width: 991px) {
				display: none;
			}
		}
	}

	&-img {
		width: 60%;

		@media screen and (max-width: 991px) {
			width: 100%;
			height: 100%;
		}

		img {
			width: 148%;
		}
	}

	.text-bottom-container {
		padding-block: 64px;

		display: flex;
		justify-content: center;

		@media screen and (max-width: 991px) {
			padding-block: 0px 64px;
		}

		.text-bottom {
			position: absolute;
			bottom: 10%;
			left: 50%;
			transform: translateX(-50%);

			@include font-xxl(700, normal, #ed672c, 2.6rem, uppercase);
			font-family: Montserrat;
			line-height: 47px;
			text-align: center;

			@media screen and (max-width: 991px) {
				position: static;
				transform: translateX(0);
				@include font-xxl(700, normal, #ed672c, 2rem, uppercase);
			}
		}
	}
}

.programs {
	display: flex;
	flex-direction: column;

	@media screen and (max-width: 1200px) {
		margin-top: 10%;
	}

	&-upper {
		display: flex;
		flex-direction: row;

		@media screen and (max-width: 991px) {
			flex-wrap: wrap;
		}

		&-left {
			display: flex;
			flex-direction: row;
			max-height: 1134px;
			height: 100%;
			background-color: #fff;

			@media screen and (max-width: 1800px) {
				width: 75%;
			}

			@media screen and (max-width: 1200px) {
				flex-direction: column;
				height: auto;
				max-height: none;
				width: 50%;
			}

			@media screen and (max-width: 786px) {
				width: 100%;
			}

			.left-half {
				/* max-width: 724px; */
				width: 60%;
				background-color: #ed672c;
				border-radius: 260px 0px 0px 0px;

				@media screen and (max-width: 1200px) {
					width: 100%;
					border-radius: 0px 260px 0px 0px;
					padding: 10% 5% 5% 5%;
				}

				.content {
					color: #0036b1;
					padding-block: 146px 115px;
					width: 100%;
					max-width: 414px;
					margin: auto;

					@media screen and (max-width: 1200px) {
						border-radius: 0px 260px 0px 0px;
						padding-block: 10% 5%;
						width: 80%;
					}

					.fitness-title {
						@include font-xxl(700, normal, #c4fb71, 1.8125rem, uppercase);
						font-family: Montserrat;
						line-height: 33px;
						margin-bottom: 31px;

						@media screen and (max-width: 786px) {
							font-size: 1.5rem;
						}
					}

					.fitness-desc {
						@include font-xxl(500, normal, #fff, 1.1875rem, none);
						font-family: Montserrat;
						line-height: 32px;

						@media screen and (max-width: 786px) {
							width: 90%;
							font-size: 0.9rem;
							line-height: 22px;
						}
					}
				}

				.img-container {
					img {
						/* width: 100%; */
						/* @media screen and (max-width: 1800px) {
							width: 50%;
						} */
						@media screen and (max-width: 1200px) {
							display: none;
						}
					}
				}
			}

			.right-half {
				.fitness-left-cropped {
					display: block;
					width: 100%;
					height: 1300px;
					object-fit: cover;
					border-radius: 0px 260px 0px 0px;

					@media screen and (max-width: 1200px) {
						display: none;
					}
				}

				.fitness-left {
					display: none;

					@media screen and (max-width: 1200px) {
						display: block;
						width: 100%;
					}
				}
			}
		}

		&-right {
			background-color: #fdf151;
			border-radius: 260px 0px 0px 0px;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 40%;

			@media screen and (max-width: 1200px) {
				width: 50%;
			}

			@media screen and (max-width: 786px) {
				width: 100%;
				height: 800px;
				border-radius: 0px;
			}

			img {
				position: absolute;
				top: 91px;
				right: 91px;

				@media screen and (max-width: 786px) {
					top: 20px;
					right: 20px;
					width: 35%;
				}

				@media screen and (max-width: 500px) {
					width: 55%;
				}
			}

			.content {
				margin-top: 270px;
				width: 60%;

				@media screen and (max-width: 786px) {
					width: 100%;
					padding-inline: 5%;
				}

				.header {
					@include font-xxl(700, normal, #1c5ef6, 1.1rem, uppercase);
					font-family: Montserrat;
					font-size: 1.8125rem;
					line-height: 33px;
					padding-bottom: 31px;

					@media screen and (max-width: 786px) {
						font-size: 1.5rem;
					}
				}

				.desc {
					@include font-xxl(500, normal, #1c5ef6, 1.1rem, none);
					font-family: Montserrat;
					font-size: 1.1875rem;
					line-height: 32px;

					@media screen and (max-width: 786px) {
						top: 20px;
						right: 20px;
						width: 60%;
					}

					@media screen and (max-width: 786px) {
						font-size: 1rem;
						width: 100%;
					}
				}
			}
		}
	}

	&-lower {
		display: flex;
		flex-direction: row;
		background-color: #1c5ef6;
		border-radius: 0px 0px 239.5px 0px;

		@media screen and (max-width: 786px) {
			flex-direction: column;
		}

		.header {
			@include font-xxl(700, normal, #1c5ef6, 1.8125rem, uppercase);
			font-family: Montserrat;
			line-height: 33px;
			margin-bottom: 31px;
			margin-top: 20%;
		}

		.desc,
		ul > li {
			@include font-xxl(500, normal, #1c5ef6, 1.1875rem, none);
			font-family: Montserrat;
			line-height: 32px;
		}

		&-left {
			background-color: #c4fb71;
			display: flex;
			flex-direction: column;
			gap: 31px;
			padding-inline: 5%;
			width: 50%;
			position: relative;

			@media screen and (max-width: 786px) {
				width: 100%;
			}

			.content {
				max-width: 512px;
				width: 100%;

				.header {
					@include font-xxl(700, normal, #1c5ef6, 1.8125rem, uppercase);
					font-family: Montserrat;
					line-height: 33px;
					margin-bottom: 31px;
					margin-top: 20%;
				}

				.desc,
				ul > li {
					@include font-xxl(500, normal, #1c5ef6, 1.1875rem, none);
					font-family: Montserrat;
					line-height: 32px;
				}

				.list-header {
					@include font-xxl(800, italic, #1c5ef6, 1.1875rem, none);
					font-family: Montserrat;
					line-height: 32px;
					margin-bottom: 31px;
				}

				ul {
					margin-left: 20px;
					padding-bottom: 2rem;
				}

				ul > li::marker {
					font-size: 0.5rem;
					color: #1c5ef6;
				}

				.join-us-btn {
					padding: 15px 30px;
					border-radius: 0px 29px 29px 29px;
					background-color: #ed672c;
					border: none;
					text-decoration: none;

					line-height: 21.94px;
					letter-spacing: 0.08em;
					margin-top: 75px;

					@include font-xxl(500, normal, #fff, 1.1rem, uppercase);
				}
			}

			.img-container {
				img {
					position: absolute;
					bottom: 0px;
					right: -11%;

					@media screen and (min-width: 1200px) and (max-width: 1920px) {
						width: 60%;
					}

					@media screen and (max-width: 1200px) {
						display: none;
					}
				}
			}
		}

		&-right {
			width: 50%;

			@media screen and (max-width: 786px) {
				width: 100%;
			}

			.content {
				padding-block: 10%;

				@media screen and (max-width: 1200px) {
					padding-block: 20%;
				}
			}

			.imgs-container {
				img {
					width: 50%;
					height: 500px;

					@media screen and (max-width: 1200px) {
						width: 100%;
						height: 400px;
						object-fit: cover;
					}

					@media screen and (min-width: 1000px) and (max-width: 1200px) {
						height: 500px;
					}
				}

				.right-upper-right {
					display: inline-block;

					@media screen and (max-width: 1200px) {
						display: none;
					}
				}
			}

			.header,
			.desc {
				color: #c4fb71;
				padding-inline: 15% 0%;
				width: 70%;
				margin-top: 0;

				@media screen and (max-width: 1200px) {
					padding-inline: 15%;
				}
			}

			.header {
				@media screen and (max-width: 1200px) {
					width: 50%;
				}
			}

			.desc {
				@media screen and (max-width: 1200px) {
					width: 100%;
				}
			}

			.right-upper-right-resp {
				display: none;

				@media screen and (max-width: 786px) {
					width: 100%;
				}
			}
		}
	}
}

.memberships {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	padding-block: 15% 8%;

	&-container {
		display: flex;
	}

	@media screen and (max-width: 991px) {
		padding: 7% 12%;
	}

	.left-side {
		transform: translate(11%);

		@media screen and (max-width: 991px) {
			transform: translate(0%);
		}

		.sub-header {
			@include font-xxl(500, normal, #1c5ef6, 1.1875rem, uppercase);
			font-family: Montserrat;
			line-height: 47px;
			letter-spacing: 0.46em;

			@media screen and (max-width: 991px) {
				font-size: 0.9rem;
			}
		}

		.header {
			@include font-xxl(700, normal, #ed672c, 2rem, uppercase);
			font-family: Montserrat;
			line-height: 47px;
			max-width: 840px;
			width: 100%;

			@media screen and (max-width: 991px) {
				font-size: 1.5rem;
			}

			@media screen and (max-width: 786px) {
				font-size: 1.3rem;
			}
		}

		.cards {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			/* gap: 14px; */
			margin-top: 5%;
			gap: 8px;
			@media screen and (max-width: 991px) {
				grid-template-columns: repeat(1, 1fr);
			}

			.green-card,
			.orange-card {
				padding-inline: 4%;
				height: 21rem;
				@media screen and (max-width: 991px) {
					height: auto;
					padding: 8%;
				}
				max-width: 466.54px;
				width: 100%;
				display: flex;
				justify-content: center;
				align-items: start;
				flex-direction: column;
				padding-inline: 10%;
				gap: 9px;

				.desc {
					@include font-xxl(400, normal, #1c5ef6, 1.3rem, none);
					font-family: Montserrat;
					line-height: 34.2px;

					@media screen and (max-width: 991px) {
						font-size: 1.2rem;
					}
				}

				.bold-desc {
					@include font-xxl(600, normal, #1c5ef6, 1.3rem, none);

					@media screen and (max-width: 991px) {
						font-size: 1.2rem;
					}
				}
			}

			.green-card {
				background-color: #c4fb71;
			}

			.orange-card {
				background-color: #ed672c;
				border-radius: 0px 217px 0px 0px;

				flex-direction: row-reverse;
				align-items: end;
				justify-content: space-between;
				padding-bottom: 36px;
				max-width: 300px;

				a {
					text-decoration: none;
				}

				.desc {
					@include font-xxl(500, normal, #ffffff, 1.4rem, none);
					font-family: Montserrat;
					line-height: 26.82px;
					letter-spacing: 0.08em;

					@media screen and (max-width: 991px) {
						font-size: 1.2rem;
					}
				}
			}
		}
	}

	.right-side {
		transform: translate(-11%);

		img {
			width: 100%;

			@media screen and (max-width: 991px) {
				display: none;
			}
		}
	}
}

.footer {
	background-color: var(--color-yellow);

	&-cols {
		display: grid;
		justify-content: start;
		align-items: center;
		grid-template-columns: 1fr 1fr;

		@media screen and (max-width: 991px) {
			grid-template-columns: 1fr;
		}
	}

	&-links {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		height: 100%;

		&-about {
			padding-inline: 8%;
			padding-bottom: 3%;
			height: 42%;
			background-color: var(--color-blue);
			display: flex;
			flex-direction: column;
			justify-content: flex-end;

			@media screen and (max-width: 991px) {
				height: 70%;
			}

			&-logo {
				width: 15rem;
			}

			h4 {
				color: #c4fb71;
				font-size: 1.1rem;
				font-style: normal;
				font-weight: 500;
				line-height: 2rem;
				width: 60%;
				padding-inline-start: 2%;
				padding-bottom: 5%;

				@media screen and (max-width: 991px) {
					width: 100%;
				}
			}

			&-social {
				display: flex;
				justify-content: flex-end;
				gap: 1rem;
			}
		}

		&-contact {
			background-color: var(--color-yellow);
			display: flex;
			padding: 8%;
			gap: 10%;

			@media screen and (max-width: 991px) {
				flex-direction: column;
				justify-content: space-between;
				gap: 1rem;
			}

			ul {
				list-style: none;

				li {
					h5 {
						text-transform: uppercase;
					}
					a {
						text-decoration: none;
						color: #1c5ef6;
					}
					margin-bottom: 1rem;
					color: #1c5ef6;
					font-size: 1.3rem;
					font-style: normal;
					font-weight: 400;
				}
			}
		}
	}

	&-media {
		img {
			width: 100%;
		}
	}

	&-passion {
		background-color: #ed672c;
		display: flex;
		justify-content: space-between;
		padding-inline: 4%;
		padding-top: 1rem;
		padding-bottom: 1rem;

		@media screen and (max-width: 991px) {
			flex-direction: column;
			gap: 1rem;
			align-items: center;
		}

		span {
			opacity: 0.8;
			color: #fff;
			font-size: 0.875rem;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			letter-spacing: 0.175rem;
			text-transform: uppercase;

			a {
				color: #c4fb71;
				text-decoration: none;
			}
		}
	}
}
