@import '../../styles/typography.scss';

.button {
    padding: 15px 30px;
    border-radius: 0px 29px 29px 29px;
    background-color: #ED672C;
    border: none;
    text-decoration: none;
    font-family: Montserrat;
    line-height: 21.94px;
    letter-spacing: 0.08em;
    @include font-xxl(500, normal, #fff, 1.1rem, uppercase);
    transition: all 0.3s;
    
}