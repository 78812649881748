.navbar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: absolute;
	width: 100%;
	padding-inline: 5%;
	padding-top: 1rem;
	z-index: 9;
	@media screen and (max-width: 769px) {
	
		padding: 2.5%;
	}

	&-logo {
		width: 10rem;
		position: relative;
		z-index: 1;

		@media screen and (max-width: 1250px) {
			width: 7.5rem;
		}

		&-icon {
			max-width: 100%;
			object-fit: contain;
			cursor: pointer;
		}
	}

	&-links {
		list-style: none;
		display: flex;
		align-items: center;
		gap: 2rem;
		margin-inline-start: 1rem;

		@media screen and (max-width: 769px) {
			display: none;
		}
	}

	&-link {
		position: relative;
		text-transform: uppercase;
		font-size: 1.375rem;
		font-weight: 400;
		color: var(--color-blue);
		cursor: pointer;
	}


	&-menu {
		position: fixed;
		z-index: 100;
		background-color: #fff;
		inset: 0;
		inset-inline-start: 40%;
		padding-top: 5rem;
		padding-left: 1rem;
		animation: openMenu 0.3s ease-in-out forwards;

		&-close {
			cursor: pointer;
			position: absolute;
			width: 2rem;
			inset-block-start: 1.5rem;
			inset-inline-end: 1.5rem;
		}

		&-links {
			list-style: none;
			padding: 0;
			display: flex;
			flex-direction: column;
			gap: 2rem;
		}

		&-link {
			font-size: 1rem;
			width: max-content;
			text-transform: uppercase;
			color: var(--font-color-primary);
			cursor: pointer;
			display: flex;
			flex-direction: column;

			&:last-child {
				align-self: center;
			}

			span {
				font-size: 1.25rem;
				align-self: flex-end;
				position: relative;
				display: flex;
				align-items: center;
				gap: 0.5rem;

				&::before {
					content: '';
					display: flex;
					align-items: center;
					width: 0.65rem;
					aspect-ratio: 1;
					background-color: var(--font-color-secondary);
					border-radius: 50%;
				}
			}

		}

		@media screen and (max-width: 575px) {
			padding: 5rem 3rem;
			inset-inline-start: 20%;
		}

		&-action {
			all: unset;
			box-sizing: border-box;
			cursor: pointer;
			display: none;
			height: 0.2rem;
			width: 2.5rem;
			border-radius: 0.2rem;
			background-color:var(--color-blue);
			position: relative;

			&::after,
			&::before {
				box-sizing: border-box;
				content: '';
				display: block;
				box-sizing: content-box;
				height: 0.2rem;
				width: 2.5rem;
				border-radius: 0.2rem;
				background-color: var(--color-blue);
				position: absolute;
				inset-block-start: 0;
				inset-inline-start: 30%;
			}

			&::before {
				transform: translate(-50%, 0.75rem);
			}

			&::after {
				transform: translate(-50%, -0.75rem);
			}

			@media screen and (max-width: 769px) {
				display: block;
			}
		}
	}
}

@keyframes openMenu {
	from {
		inset-inline-start: 100%;
	}

	to {
		inset-inline-start: 40%;
	}
}