@import url("https://use.typekit.net/xwm7gsa.css");


* {
  font-family: "montserrat", sans-serif;
}

:root {
  --color-yellow: #FDF151;
  --color-blue: #1C5EF6;
}
